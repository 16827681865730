import React from 'react'

import './RestaurantLogos.less'

const RestaurantLogos = () => {
  return (
    <div>
      <div className="restaurant-logos-green">
        <img className="restaurant-logos-green__logo-1" src="/images/restaurants-logos/green/logo-just-salad.svg" />
        <img className="restaurant-logos-green__logo-2" src="/images/restaurants-logos/green/logo-beatnic.svg" />
        <img className="restaurant-logos-green__logo-4" src="/images/restaurants-logos/green/logo-naya.svg" />
        <img className="restaurant-logos-green__logo-5" src="/images/restaurants-logos/green/logo-inday.svg" />
        <img className="restaurant-logos-green__logo-6" src="/images/restaurants-logos/green/logo-oxido.svg" />
        <img className="restaurant-logos-green__logo-7" src="/images/restaurants-logos/green/logo-little-beet.svg" />
      </div>
      <div className="restaurant-logos-green-mobile">
        <img className="restaurant-logos-green-mobile__logo-5" src="/images/restaurants-logos/green/logo-inday.svg" />
        <img className="restaurant-logos-green-mobile__logo-6" src="/images/restaurants-logos/green/logo-oxido.svg" />
        <img
          className="restaurant-logos-green-mobile__logo-7"
          src="/images/restaurants-logos/green/logo-little-beet.svg"
        />
      </div>
    </div>
  )
}

export default RestaurantLogos
