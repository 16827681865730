import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useState } from 'react'
import * as rdd from 'react-device-detect'
import { FiAlertCircle } from 'react-icons/fi'

import { ValidateEmail, ValidateEmailByLength, ValidatePostCode, ValidateZip } from '../../helpers'
import useHomepage from '../../hooks/useHomepage'
import Country from '../../models/Country'
import Invite from '../../models/Invite'
import UtmContent from '../../models/UtmContent'
import { AnalyticsService, ApiService } from '../../services'
import { Analytics } from '../../services/Analytics'
import { CityType } from '../../types'
import InlineSpinner from '../common/InlineSpinner'
import './GreenForm.less'

type Props = {
  action?: string
  customClass?: string
  position: string
  cities: CityType[]
}

type State = {
  email: string
  zip: string
  postCode: string
  emailValid: boolean
  zipValid: boolean
  invite: Invite | null
  loading: boolean
  actionName: string
  emailMarketingConsent: boolean
}

const GreenForm: React.FC<Props> = ({ action, customClass, position, cities }) => {
  const [state, setState] = useState<State>({
    invite: null,
    email: '',
    zip: '',
    postCode: '',
    emailMarketingConsent: false,
    emailValid: true,
    zipValid: true,
    loading: false,
    actionName: action || 'Browse Restaurants',
  })

  const { productOffering, showModal, hideModal, launchConfirmationRequired } = useHomepage()
  const { t } = useTranslation()
  const language = useI18next().language
  const utmContent = new UtmContent({ position: position }).toString()
  const country = new Country({ language })
  const countryCode = country.code()
  const isZipRequired = country.isZipRequired()
  const isEmailMarketingConsetRequired = country.isEmailMarketingConsetRequired()

  const platform = () => {
    let device
    if (rdd.isIOS) {
      device = 'ios'
    } else if (rdd.isBrowser) {
      device = 'web'
    } else if (rdd.isAndroid) {
      device = 'android'
    } else {
      rdd.deviceType
    }

    return `${device}_${rdd.browserName
      .toLowerCase()
      .replace(/mobile/i, '')
      .trim()}`
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (state.loading) return

    const emailValid = ValidateEmail(state.email) && ValidateEmailByLength(state.email)
    const zipValid = isZipValid()

    if (emailValid && zipValid) {
      setState({ ...state, emailValid, zipValid, loading: true })

      AnalyticsService.trackEvent({
        action: 'Click',
        category: 'Homepage',
        label: state.actionName,
      })

      window.ttq.track('SubmitForm', {
        content_id: 'homepage_form',
      })

      ApiService.createInvite(
        {
          email: state.email,
          zip: state.zip,
          post_code: state.postCode,
          country_code: countryCode,
          utm: utmContent,
          email_marketing_consent: !isEmailMarketingConsetRequired || state.emailMarketingConsent,
        },
        {
          platform: platform(),
          location: productOffering === 'dinner' ? 'suburb-homepage' : 'homepage',
        },
      ).then(async ({ data }) => {
        const invite = new Invite(data.invite)

        try {
          const postalCode = await ApiService.searchPostalCode({
            country_code: countryCode,
            postal_code: state.zip || state.postCode,
          })
          await ApiService.updateInvite({ id: invite.id, city_name: postalCode.city.name })
          const url = invite.getRedirectUrl(utmContent)

          Analytics.GTMTriggerSignupFunnelEvent('lander_submit')
          Analytics.GTMPushHashedEvent({
            invitesobjectid: invite.id,
            zipcode: postalCode,
            email: invite.email,
            event: 'emailsignup_event',
          })

          if (productOffering === 'dinner' && launchConfirmationRequired) {
            setState({ ...state, emailValid, zipValid, loading: false, invite })
            hideModal('get-access-form')
            return showModal({ name: 'launching-soon-confirmation' })
          }

          window.location.href = url
        } catch {
          setState({ ...state, emailValid, zipValid, loading: false, invite })

          if (productOffering === 'dinner') {
            if (launchConfirmationRequired) {
              hideModal('get-access-form')
              return showModal({ name: 'launching-soon-confirmation' })
            }

            const url = invite.getRedirectUrl(utmContent)
            window.location.href = url
          } else {
            showModal({ name: 'select-city', settings: { cities, invite, utmContent } })
          }
        }
      })
    } else {
      setState({ ...state, emailValid, zipValid })
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    setState({ ...state, [target.name]: value })
  }

  const isZipValid = () => {
    return isZipRequired ? ValidateZip(state.zip) : ValidatePostCode(state.postCode)
  }

  const zipElement = () => {
    const defaultProps = {
      className: `green-form__input green-form__input--zip ${!state.zipValid && 'green-form__input--error'}`,
      type: 'text',
      onChange: onChangeHandler,
    }

    if (isZipRequired) {
      return <input {...defaultProps} name="zip" value={state.zip} placeholder={t('Zip Code')} />
    } else {
      return <input {...defaultProps} name="postCode" value={state.postCode} placeholder={t('Post Code')} />
    }
  }

  return (
    <>
      <form className={`green-form ${customClass}`} onSubmit={onSubmitHandler}>
        <input
          className={`green-form__input green-form__input--email ${!state.emailValid && 'green-form__input--error'}`}
          type="text"
          name="email"
          value={state.email}
          placeholder={t('Email')}
          onChange={onChangeHandler}
        />
        {!state.emailValid && (
          <div className="green-form__error-message">
            <FiAlertCircle /> {t('Invalid email address')}
          </div>
        )}
        <div className="green-form__group">
          {zipElement()}
          {!state.zipValid && (
            <div className="green-form__error-message green-form__error-message--zip-mobile">
              <FiAlertCircle /> {isZipRequired ? t('Invalid zip code') : t('Invalid post code')}
            </div>
          )}
        </div>
        {!state.zipValid && (
          <div className="green-form__error-message green-form__error-message--zip-desktop">
            <FiAlertCircle /> {isZipRequired ? t('Invalid zip code') : t('Invalid post code')}
          </div>
        )}
        <button className="green-form__action green-cta" type="submit">
          {state.loading ? <InlineSpinner /> : action || t('Redeem free meal')}
        </button>
        {isEmailMarketingConsetRequired && (
          <div className="green-form__email-consent">
            <label className={state.emailMarketingConsent ? 'checked' : ''}>
              <input
                type="checkbox"
                name="emailMarketingConsent"
                checked={state.emailMarketingConsent}
                onChange={onChangeHandler}
              />
              {t('Yes, I want first dibs on promos!')}
            </label>
          </div>
        )}
      </form>
    </>
  )
}

export default GreenForm
