import { Player } from '@lottiefiles/react-lottie-player'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import useHomepage from '../../../hooks/useHomepage'
import Collage from '../../../images/collage.png'
import MobileCollage from '../../../images/mobile-collage.png'
import ScrollAnimation from '../../scroll-animation/Container'
import Action from './Action'
import './Hero.less'

const Difference = () => {
  const { animationProps } = useHomepage()
  const { t } = useTranslation()

  return (
    <div className="green-hero__difference">
      <ScrollAnimation {...animationProps}>
        <div className="green-hero__difference-collage">
          <img src={Collage} />
        </div>
        <div className="green-hero__difference-mobile-collage">
          <img src={MobileCollage} />
        </div>
        <div className="green-hero__difference-bottom">
          <div className="green-hero__difference-left">
            <div className="green-hero__difference-earth">
              <Player src="https://lottie.host/60fcc765-b9b1-4255-b71d-f4856429801e/5rLBHCKqu4.json" loop autoplay />
            </div>
          </div>
          <div className="green-hero__difference-right">
            <div className="green-hero__difference-title">{t('Together, we can make a difference.')}</div>
            <br />
            <div className="green-hero__difference-info">
              Our reusable containers are designed with the planet in mind.
            </div>
            <br />
            <div className="green-hero__difference-info">
              Reusing this container emits less greenhouse gas than making a new container or recycling one. By choosing
              reusable, you’re making a conscious choice to cut down on carbon emissions. 🌿 🌍 ♻️
            </div>
            <br />
            <Action reserveWithReusable={false} />
          </div>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default Difference
