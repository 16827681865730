import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import useHomepage from '../../../hooks/useHomepage'
import EnjoyMeal from '../../../images/enjoy-meal.png'
import GreenBin from '../../../images/green-bin.png'
import ScanContainer from '../../../images/scan-container.png'
import ScrollAnimation from '../../scroll-animation/Container'
import Action from './Action'
import './Hero.less'

const HowItWorks = () => {
  const { animationProps } = useHomepage()
  const { t } = useTranslation()

  return (
    <div className="green-hero__how-it-works">
      <ScrollAnimation {...animationProps}>
        <div className="green-hero__how-it-works-title">{t('How it works')}</div>
        <div className="green-hero__image-row">
          <div className="green-hero__scan">
            <div>Scan</div>
            <div>
              <img src={ScanContainer} width="66%" />
            </div>
            <div className="green-hero__how-it-works-info">Scan the QR Code on your container.</div>
          </div>
          <div className="green-hero__enjoy">
            <div>Enjoy</div>
            <div>
              <img src={EnjoyMeal} width="66%" />
            </div>
            <div className="green-hero__how-it-works-info">Enjoy your meal!</div>
            <br />
          </div>
          <div className="green-hero__return">
            <div>Return</div>
            <div>
              <img src={GreenBin} width="66%" />
            </div>
            <div className="green-hero__how-it-works-info">Drop off your container in any Green Bin.</div>
          </div>
        </div>
        <Action reserveWithReusable={true} />
      </ScrollAnimation>
    </div>
  )
}

export default HowItWorks
