import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'

import useHomepage from '../../../hooks/useHomepage'
import GreenArrowDown from '../../../images/green-arrow-down.png'
import PinImage from '../../../images/green-map-pin-hover.png'
import HeartFriendContainer from '../../../images/heart-friend-container.png'
import UtmContent from '../../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../../services'
import { ReusableDropoffType } from '../../../types'
import './map.less'

type Props = {
  reusableDropoff: ReusableDropoffType | undefined
  isSectionInView: boolean
}

const ReusableDropoff: React.FC<Props> = ({ reusableDropoff, isSectionInView }) => {
  const { t } = useTranslation()
  const { activeCityName, productOffering, showModal, launchConfirmationRequired, onNyLunchPage } = useHomepage()
  const [url, setUrl] = useState('')

  const setAnimation = useState<string>('')[1]

  useEffect(() => {
    updateActiveCity()
  }, [productOffering, onNyLunchPage])

  useEffect(() => {
    const animationSource = isSectionInView
      ? `https://images.mealpal.com/circle-transparent.gif?a=${Math.random()}`
      : ''
    setAnimation(animationSource)
  }, [isSectionInView])

  const onClickHandler = (e: React.MouseEvent) => {
    if (launchConfirmationRequired) {
      e.preventDefault()

      return showModal({ name: 'get-access-form' })
    }

    updateActiveCity()

    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Try Now - Map',
    })
  }

  const updateActiveCity = () => {
    const position = 'green-map'
    const utmContent = new UtmContent({ position }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }

  return (
    <div className="reusable-dropoff">
      <div className="reusable-dropoff__desktop">
        <div className="reusable-dropoff__info reusable-dropoff__name--lg">
          <div className={`reusable-dropoff__name ${!reusableDropoff && 'blurred'}`}>{reusableDropoff?.name}</div>
          <div className={`reusable-dropoff__address ${!reusableDropoff && 'blurred'}`}>
            <img src={PinImage} height="30" />
            {reusableDropoff?.address}
          </div>
          <section className="reusable-dropoff__separator" />
        </div>
        <div className="reusable-dropoff__display-wrapper">
          <div className="reusable-dropoff__display-image">
            <img src={HeartFriendContainer} width="66%" />
          </div>
          <div className="reusable-dropoff__display-text">
            <img src={GreenArrowDown} height="30" />
            &nbsp;Reserve a meal for FREE!&nbsp;
            <img src={GreenArrowDown} height="30" />
          </div>
        </div>
      </div>
      <div className="reusable-dropoff__mobile">
        <div className="reusable-dropoff__display-image">
          <img src={HeartFriendContainer} width="100%" />
        </div>
        <div className="reusable-dropoff__info reusable-dropoff__name--lg">
          <div className={`reusable-dropoff__name ${!reusableDropoff && 'blurred'}`}>{reusableDropoff?.name}</div>
          <div className={`reusable-dropoff__address ${!reusableDropoff && 'blurred'}`}>
            <img src={PinImage} height="30" />
            {reusableDropoff?.address}
          </div>
          <section className="reusable-dropoff__separator" />
          <div className="reusable-dropoff__display-text">
            <div className="reusable-dropoff__display-text-group">
              <img src={GreenArrowDown} height="50%" />
              &nbsp;Reserve a meal for FREE!&nbsp;
              <img src={GreenArrowDown} height="50%" />
            </div>
          </div>
        </div>
      </div>
      <a href={url} className="reusable-dropoff__action red-cta" onClick={onClickHandler}>
        {t('Try now')} <FaAngleRight />
      </a>
    </div>
  )
}

export default ReusableDropoff
