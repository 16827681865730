import React, { useEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import SEO from '../seo'
import Hero from './hero/Hero'

const GreenContainer: React.FC<RouteProps<'/green'>> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO title="Green" />
      <Hero />
    </>
  )
}

export default GreenContainer
