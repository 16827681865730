import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import useHomepage from '../../../hooks/useHomepage'
import UtmContent from '../../../models/UtmContent'
import { AnalyticsService } from '../../../services'
import { UrlService } from '../../../services'
import './Hero.less'

type Props = {
  reserveWithReusable: boolean
}

const Action: React.FC<Props> = ({ reserveWithReusable }) => {
  const { activeCityName, productOffering, showModal, launchConfirmationRequired, onNyLunchPage, onSfLunchPage } =
    useHomepage()
  const { t } = useTranslation()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const position = reserveWithReusable ? 'green-how-it-works' : 'green-impact'
    const utmContent = new UtmContent({ position }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [productOffering, onNyLunchPage, onSfLunchPage])

  const onClickHandler = (e: React.MouseEvent) => {
    if (launchConfirmationRequired) {
      e.preventDefault()

      return showModal({ name: 'get-access-form' })
    }

    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Start Saving on Lunch Today - Top',
    })
  }

  return (
    <a
      href={url}
      className={reserveWithReusable ? 'green-hero__action red-cta' : 'green-hero__action red-cta red-cta--left'}
      onClick={onClickHandler}
    >
      {t(`${reserveWithReusable ? 'Reserve with reusable' : 'Try reusable today'}`)}
    </a>
  )
}

export default Action
