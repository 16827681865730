import { throttle } from 'lodash'
import React from 'react'

interface Style {
  animationDuration: string
  opacity?: number
}

interface ScrollAnimationProps extends React.PropsWithChildren {
  animateIn?: string
  animateOut?: string
  offset?: number
  duration?: number
  delay?: number
  initiallyVisible?: boolean
  animateOnce?: boolean
  style?: Style
  scrollableParentSelector?: string
  className?: string
  animatePreScroll?: boolean
  afterAnimatedIn?: (object: { inViewport: boolean; onScreen: boolean }) => void
  afterAnimatedOut?: (object: { inViewport: boolean; onScreen: boolean }) => void
}

interface ScrollAnimationState {
  classes: string
  style: Style
}

export default class ScrollAnimation extends React.Component<ScrollAnimationProps, ScrollAnimationState> {
  private serverSide: boolean
  private listener
  private visibility: { onScreen: boolean; inViewport: boolean }
  private scrollableParent: Element | (Window & typeof globalThis) | null = null
  private callbackTimeout: NodeJS.Timeout | number | null = null
  private delayedAnimationTimeout: NodeJS.Timeout | null = null
  private animating = false
  private node: HTMLDivElement | null = null

  static defaultProps = {
    offset: 150,
    duration: 1,
    initiallyVisible: false,
    delay: 0,
    animateOnce: false,
    animatePreScroll: true,
  }

  constructor(props: React.PropsWithChildren<ScrollAnimationProps>) {
    super(props)
    this.serverSide = typeof window === 'undefined'
    this.listener = throttle(this.handleScroll.bind(this), 50)
    this.visibility = {
      onScreen: false,
      inViewport: false,
    }

    this.state = {
      classes: 'animated',
      style: {
        animationDuration: `${this.props.duration}s`,
        opacity: this.props.initiallyVisible ? 1 : 0,
      },
    }
  }

  getElementTop(elm: HTMLDivElement) {
    let element: HTMLDivElement | null = elm
    let yPos = 0
    while (element && element.offsetTop !== undefined && element.clientTop !== undefined) {
      yPos += element.offsetTop + elm.clientTop
      element = element.offsetParent as HTMLDivElement
    }
    return yPos
  }

  getScrollPos() {
    if ((this.scrollableParent as Window).pageYOffset !== undefined) {
      return (this.scrollableParent as Window).pageYOffset
    }
    return (this.scrollableParent as Element).scrollTop
  }

  getScrollableParentHeight() {
    if ((this.scrollableParent as Window).innerHeight !== undefined) {
      return (this.scrollableParent as Window).innerHeight
    }
    return (this.scrollableParent as Element).clientHeight
  }

  getViewportTop() {
    return this.getScrollPos() + (this.props.offset || 0)
  }

  getViewportBottom() {
    return this.getScrollPos() + this.getScrollableParentHeight() - (this.props.offset || 0)
  }

  isInViewport(y: number) {
    return y >= this.getViewportTop() && y <= this.getViewportBottom()
  }

  isAboveViewport(y: number) {
    return y < this.getViewportTop()
  }

  isBelowViewport(y: number) {
    return y > this.getViewportBottom()
  }

  inViewport(elementTop: number, elementBottom: number) {
    return (
      this.isInViewport(elementTop) ||
      this.isInViewport(elementBottom) ||
      (this.isAboveViewport(elementTop) && this.isBelowViewport(elementBottom))
    )
  }

  onScreen(elementTop: number, elementBottom: number) {
    return !this.isAboveScreen(elementBottom) && !this.isBelowScreen(elementTop)
  }

  isAboveScreen(y: number) {
    return y < this.getScrollPos()
  }

  isBelowScreen(y: number) {
    return y > this.getScrollPos() + this.getScrollableParentHeight()
  }

  getVisibility() {
    const elementTop =
      this.getElementTop(this.node as HTMLDivElement) - this.getElementTop(this.scrollableParent as HTMLDivElement)
    const elementBottom = elementTop + (this.node as HTMLDivElement).clientHeight
    return {
      inViewport: this.inViewport(elementTop, elementBottom),
      onScreen: this.onScreen(elementTop, elementBottom),
    }
  }

  componentDidMount() {
    if (!this.serverSide) {
      const parentSelector = this.props.scrollableParentSelector
      this.scrollableParent = parentSelector ? document.querySelector(parentSelector) : window
      if (this.scrollableParent && this.scrollableParent.addEventListener) {
        this.scrollableParent.addEventListener('scroll', this.listener)
      } else {
        console.warn(`Cannot find element by locator: ${this.props.scrollableParentSelector}`)
      }
      if (this.props.animatePreScroll) {
        this.handleScroll()
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.delayedAnimationTimeout as NodeJS.Timeout)
    clearTimeout(this.callbackTimeout as NodeJS.Timeout)
    if (window && window.removeEventListener) {
      window.removeEventListener('scroll', this.listener)
    }
  }

  visibilityHasChanged(
    previousVis: { inViewport: boolean; onScreen: boolean },
    currentVis: { inViewport: boolean; onScreen: boolean },
  ) {
    return previousVis.inViewport !== currentVis.inViewport || previousVis.onScreen !== currentVis.onScreen
  }

  animate(animation: string, callback: (object: { inViewport: boolean; onScreen: boolean }) => void | undefined) {
    this.delayedAnimationTimeout = setTimeout(() => {
      this.animating = true
      this.setState({
        classes: `animated ${animation}`,
        style: {
          animationDuration: `${this.props.duration}s`,
        },
      })
      this.callbackTimeout = setTimeout(callback, (this.props.duration || 0) * 1000)
    }, this.props.delay)
  }

  animateIn(callback?: (object: { inViewport: boolean; onScreen: boolean }) => void | undefined) {
    this.animate(this.props.animateIn || '', () => {
      if (!this.props.animateOnce) {
        this.setState({
          style: {
            animationDuration: `${this.props.duration}s`,
            opacity: 1,
          },
        })
        this.animating = false
      }
      const vis = this.getVisibility()
      if (callback) {
        callback(vis)
      }
    })
  }

  animateOut(callback?: (object: { inViewport: boolean; onScreen: boolean }) => void) {
    this.animate(this.props.animateOut || '', () => {
      this.setState({
        classes: 'animated',
        style: {
          animationDuration: `${this.props.duration}s`,
          opacity: 0,
        },
      })
      const vis = this.getVisibility()
      if (vis.inViewport && this.props.animateIn) {
        this.animateIn(this.props.afterAnimatedIn)
      } else {
        this.animating = false
      }

      if (callback) {
        callback(vis)
      }
    })
  }

  handleScroll() {
    if (!this.animating) {
      const currentVis = this.getVisibility()
      if (this.visibilityHasChanged(this.visibility, currentVis)) {
        clearTimeout(this.delayedAnimationTimeout as NodeJS.Timeout)
        if (!currentVis.onScreen) {
          this.setState({
            classes: 'animated',
            style: {
              animationDuration: `${this.props.duration}s`,
              opacity: this.props.initiallyVisible ? 1 : 0,
            },
          })
        } else if (currentVis.inViewport && this.props.animateIn) {
          this.animateIn(this.props.afterAnimatedIn)
        } else if (
          currentVis.onScreen &&
          this.visibility.inViewport &&
          this.props.animateOut &&
          this.state.style.opacity === 1
        ) {
          this.animateOut(this.props.afterAnimatedOut)
        }
        this.visibility = currentVis
      }
    }
  }

  render() {
    const classes = this.props.className ? `${this.props.className} ${this.state.classes}` : this.state.classes
    return (
      <div
        ref={(node) => {
          this.node = node
        }}
        className={classes}
        style={Object.assign({}, this.state.style, this.props.style)}
      >
        {this.props.children}
      </div>
    )
  }
}
