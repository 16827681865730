import { find, isEmpty, random } from 'lodash'
import React, { useEffect, useState } from 'react'

import useHomepage from '../../../hooks/useHomepage'
import useVisibility from '../../../hooks/useVisibility'
import { ReusableDropoffType } from '../../../types'
import ScrollAnimation from '../../scroll-animation/Container'
import MapboxGLMap from './MapboxGLMap'
import ReusableDropoff from './ReusableDropoff'
import './map.less'

const GreenMap = () => {
  const { reusableDropoffs, activeReusableDropoffId, setActiveReusableDropoffId, productOffering, animationProps } =
    useHomepage()

  const [currentReusableDropoffs, setCurrentReusableDropoffs] = useState<ReusableDropoffType[]>([])
  const [isSectionInView, parentElement] = useVisibility<HTMLDivElement>(100)

  useEffect(() => {
    if (isEmpty(reusableDropoffs)) return
    const rand = random(reusableDropoffs.length - 1)
    const activeReusableDropoff = reusableDropoffs[rand]

    setCurrentReusableDropoffs(reusableDropoffs)
    setActiveReusableDropoffId(activeReusableDropoff ? activeReusableDropoff.id : '')
  }, [reusableDropoffs])

  const activeReusableDropoff = find(
    currentReusableDropoffs,
    (r: ReusableDropoffType) => r.id === activeReusableDropoffId,
  )

  return (
    <div className={`green-map green-map--${productOffering}`} ref={parentElement}>
      <div id="map" />
      <ScrollAnimation {...animationProps}>
        <div className="green-map__title">
          Available at the <span className="green-map__title--underline">best restaurants in NYC</span>
        </div>
      </ScrollAnimation>
      <ScrollAnimation {...animationProps}>
        <div className="green-map__wrapper">
          <div className="green-map__element">
            <MapboxGLMap activeReusableDropoff={activeReusableDropoff} reusableDropoffs={currentReusableDropoffs} />
            {isEmpty(currentReusableDropoffs) && <div className="green-map__blur" />}
          </div>
          <ReusableDropoff reusableDropoff={activeReusableDropoff} isSectionInView={isSectionInView} />
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default GreenMap
