import { throttle } from 'lodash'
import { createRef, useEffect, useState } from 'react'

const useVisibility = <Element extends HTMLElement>(
  throttleMilliseconds = 100,
  offset = 0,
): [boolean, React.RefObject<Element>] => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const currentElement = createRef<Element>()

  const onScroll = throttle(() => {
    if (!currentElement.current) {
      setIsVisible(false)
      return
    }

    const top = currentElement.current.getBoundingClientRect().top
    const bottom = currentElement.current.getBoundingClientRect().bottom
    const topVisible = top <= window.innerHeight
    const bottomVisible = bottom - offset <= window.innerHeight

    setIsVisible((topVisible && bottomVisible) || (!topVisible && bottomVisible))
  }, throttleMilliseconds)

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  })

  return [isVisible, currentElement]
}

export default useVisibility
