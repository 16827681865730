import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { MOBILE_VIEW_BREAKPOINT } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import Container from '../../../images/container.png'
import UtmContent from '../../../models/UtmContent'
import { UrlService } from '../../../services'
import GreenForm from '../../form/GreenForm'
import ScrollAnimation from '../../scroll-animation/Container'
import './form.less'

const HeroForm: React.FC = () => {
  const isMobile = typeof window != 'undefined' && window.innerWidth < MOBILE_VIEW_BREAKPOINT
  const { cities, activeCityName, animationProps } = useHomepage()
  const setUrl = useState('')[1]
  const [showChild, setShowChild] = useState(false)
  const position = 'green-hero'

  const { t } = useTranslation()
  const language = useI18next().language

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'green-hero' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  useEffect(() => {
    setShowChild(true)
  }, [])

  if (!showChild) {
    return <></>
  }

  return (
    <ScrollAnimation {...animationProps} animateIn={isMobile ? 'fadeInUpForm' : 'defaultAnimation'}>
      <div className={`green-hero-form__form green-hero-form__form--${language}`}>
        <ScrollAnimation {...animationProps} animateIn={isMobile ? 'defaultAnimation' : 'fadeIn'}>
          <div className="green-hero-form__title">{t('Introducing: Reusable Packaging!')}</div>
        </ScrollAnimation>
        <div className="green-hero-form__bottom">
          <div className="green-hero-form__left">
            <ScrollAnimation {...animationProps} animateIn={isMobile ? 'defaultAnimation' : 'fadeIn'}>
              <div className="green-hero-form__subtitle">
                {t('Now you can save time, money, and the environment with MealPal!')}
              </div>
              <br />
            </ScrollAnimation>
            <ScrollAnimation {...animationProps} animateIn={isMobile ? 'defaultAnimation' : 'fadeInLeft'}>
              <div className="green-hero-form__subtitle">
                Try it today with a <span className="green-hero-form__subtitle--underline">FREE</span> meal!
              </div>
              <GreenForm customClass="green-form--hero" position={position} cities={cities} />
            </ScrollAnimation>
          </div>
          <div className="green-hero-form__right">
            <ScrollAnimation {...animationProps} animateIn={isMobile ? 'defaultAnimation' : 'fadeIn'}>
              <img src={Container} />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default HeroForm
