import mapboxgl, { GeoJSONSource, Map } from '!mapbox-gl'
import { find, isEmpty } from 'lodash'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import useHomepage from '../../../hooks/useHomepage'
import MapPinHover from '../../../images/green-map-pin-hover.png'
import MapPin from '../../../images/green-map-pin.png'
import { ReusableDropoffType } from '../../../types'
import { buildFeature, getCityCenter } from './helpers'
import './map.less'

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN || ''

type Props = {
  reusableDropoffs: ReusableDropoffType[]
  activeReusableDropoff: ReusableDropoffType | undefined
}

const MapboxGLMap: React.FC<Props> = ({ reusableDropoffs, activeReusableDropoff }) => {
  const [map, setMap] = useState<mapboxgl.Map>()
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const { setActiveReusableDropoffId } = useHomepage()
  const defaultZoom = 13

  useLayoutEffect(() => {
    const mapInstance = new Map({
      container: mapContainerRef.current as HTMLDivElement,
      style: 'mapbox://styles/mealpal-engineering/clk8jvk9y031s01p7dkc46gd4',
      zoom: defaultZoom,
      minZoom: 11,
      maxZoom: 17,
      refreshExpiredTiles: false,
      dragRotate: false,
      logoPosition: 'bottom-left',
      center: [-73.98759567981301, 40.755359477278915],
    })
    mapInstance.scrollZoom.disable()

    mapInstance.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-left')

    const onMouseEnterEventHander = (e: {
      point: mapboxgl.PointLike | [mapboxgl.PointLike, mapboxgl.PointLike] | undefined
    }) => {
      const features = mapInstance.queryRenderedFeatures(e.point, { layers: ['reusable_dropoffs'] })
      const feature = find(features, (f) => f.layer.id === 'reusable_dropoffs')

      if (feature !== null && feature !== undefined && feature.properties !== null) {
        mapInstance.setFilter('reusable_dropoffs-hover', ['==', 'id', feature.properties.id])
      }
    }

    const onMouseLeaveEventHander = () => {
      mapInstance.setFilter('reusable_dropoffs-hover', ['==', 'id', ''])
    }

    const onMouseClickEventHander = (e: {
      point: mapboxgl.PointLike | [mapboxgl.PointLike, mapboxgl.PointLike] | undefined
    }) => {
      const features = mapInstance.queryRenderedFeatures(e.point, { layers: ['reusable_dropoffs'] })
      const feature = find(features, (f) => f.layer.id === 'reusable_dropoffs')

      if (feature !== null && feature !== undefined && feature.properties !== null) {
        setActiveReusableDropoffId(feature.properties.id)
      }
    }

    const onMapLoadEventHandler = () => {
      mapInstance.addSource('reusable_dropoffs', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })

      mapInstance.loadImage(MapPin, (error, image) => {
        if (error) throw error

        if (image === undefined) {
          return
        }

        mapInstance.addImage('pin', image)
        mapInstance.addLayer({
          id: 'reusable_dropoffs',
          type: 'symbol',
          source: 'reusable_dropoffs',
          layout: {
            'icon-image': 'pin',
            'icon-size': 0.5,
            'icon-allow-overlap': true,
          },
          filter: ['!=', 'id', ''],
        })
      })

      mapInstance.loadImage(MapPinHover, (error, image) => {
        if (error) throw error

        if (image === undefined) {
          return
        }

        mapInstance.addImage('pinHover', image)
        mapInstance.addLayer({
          id: 'reusable_dropoffs-hover',
          type: 'symbol',
          source: 'reusable_dropoffs',
          layout: {
            'icon-image': 'pinHover',
            'icon-size': 0.5,
            'icon-allow-overlap': true,
          },
          filter: ['==', 'id', ''],
        })

        mapInstance.addLayer({
          id: 'reusable_dropoffs-active',
          type: 'symbol',
          source: 'reusable_dropoffs',
          layout: {
            'icon-image': 'pinHover',
            'icon-size': 0.5,
            'icon-allow-overlap': true,
          },
          filter: ['==', 'id', activeReusableDropoff ? activeReusableDropoff.id : ''],
        })
      })

      mapInstance.on('mouseenter', 'reusable_dropoffs', onMouseEnterEventHander)
      mapInstance.on('mouseleave', 'reusable_dropoffs', onMouseLeaveEventHander)
      mapInstance.on('click', 'reusable_dropoffs', onMouseClickEventHander)

      setMap(mapInstance)
    }

    mapInstance.on('load', onMapLoadEventHandler)

    return () => {
      mapInstance.off('mouseenter', 'reusable_dropoffs', onMouseEnterEventHander)
      mapInstance.off('mouseleave', 'reusable_dropoffs', onMouseLeaveEventHander)
      mapInstance.off('click', 'reusable_dropoffs', onMouseClickEventHander)
      mapInstance.remove()
    }
  }, [])

  useEffect(() => {
    if (!map || isEmpty(reusableDropoffs)) return

    const source = map.getSource('reusable_dropoffs') as GeoJSONSource
    const center = getCityCenter('new york city', reusableDropoffs)

    source.setData({
      type: 'FeatureCollection',
      features: reusableDropoffs.map(buildFeature),
    })

    map.setCenter(center)
    map.setZoom(defaultZoom)
  }, [map, reusableDropoffs])

  useEffect(() => {
    if (!map || !activeReusableDropoff) return

    const layer = map.getLayer('reusable_dropoffs-active')

    if (layer !== undefined) {
      map.setFilter('reusable_dropoffs-active', ['==', 'id', activeReusableDropoff.id])
    }
  }, [map, activeReusableDropoff])

  return <div id="map-view" ref={mapContainerRef} />
}

export default MapboxGLMap
