type CountryAttributes = {
  language: string
}

export default class Country {
  language: string

  constructor(params: CountryAttributes) {
    this.language = params.language
  }

  code() {
    switch (this.language) {
      case 'uk':
        return 'gbr'
      case 'nz':
        return 'nzl'
      case 'ca':
        return 'can'
      case 'au':
        return 'aus'
      case 'sg':
        return 'sgp'
      default:
        return 'usa'
    }
  }

  isZipRequired() {
    return this.language === 'us'
  }

  isEmailMarketingConsetRequired() {
    return this.language === 'nz'
  }
}
