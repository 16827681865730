import mapboxgl, { LngLatBoundsLike } from '!mapbox-gl'
import { each } from 'lodash'

import { ReusableDropoffType } from '../../../types'

const CITIES_CENTER: { [key: string]: { lat: number; lng: number } } = {
  auckland: {
    lng: 174.76269811982638,
    lat: -36.846873451568605,
  },
  austin: {
    lng: -97.74153548150537,
    lat: 30.286767850643898,
  },
  boston: {
    lng: -71.06521251317133,
    lat: 42.35756398171134,
  },
  chicago: {
    lng: -87.63331794663401,
    lat: 41.88634066517264,
  },
  denver: {
    lng: -104.99198658365549,
    lat: 39.75273147107032,
  },
  london: {
    lng: -0.11407127495056102,
    lat: 51.514548951566496,
  },
  'los angeles': {
    lng: -118.25342884455472,
    lat: 34.050910119054805,
  },
  manchester: {
    lng: -2.24125,
    lat: 53.4803675,
  },
  melbourne: {
    lng: 144.95544407672628,
    lat: -37.817818156317685,
  },
  miami: {
    lng: -80.19551052955444,
    lat: 25.770042116881044,
  },
  'new york city': {
    lng: -73.98759567981301,
    lat: 40.755359477278915,
  },
  philadelphia: {
    lng: -75.16934450890363,
    lat: 39.951270069937635,
  },
  portland: {
    lng: -122.6821720344227,
    lat: 45.519633007544826,
  },
  'san francisco': {
    lng: -122.40703101323885,
    lat: 37.79096044238777,
  },
  seattle: {
    lng: -122.33800968786443,
    lat: 47.61553140966285,
  },
  singapore: {
    lng: 103.85001972840632,
    lat: 1.2891863098445953,
  },
  sydney: {
    lng: 151.2059366772669,
    lat: -33.8705751943024,
  },
  toronto: {
    lng: -79.38682605627542,
    lat: 43.65096397998502,
  },
  'washington, dc': {
    lng: -77.04294011908047,
    lat: 38.903315168978736,
  },
  wellington: {
    lng: 174.7726843633743,
    lat: -41.286019894998205,
  },
  'boston suburbs': {
    lng: -71.417252,
    lat: 42.369951,
  },
}

export const buildFeature = (reusableDropoff: ReusableDropoffType) => {
  const feature: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties> = {
    geometry: {
      type: 'Point',
      coordinates: [reusableDropoff.longitude, reusableDropoff.latitude],
    },
    type: 'Feature',
    properties: {
      id: reusableDropoff.id,
      name: reusableDropoff.name,
      address: reusableDropoff.address,
    },
  }

  return feature
}

export const featuresBounds = (features: Array<GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>>) => {
  const bounds = new mapboxgl.LngLatBounds()
  each(features, (feature) => bounds.extend(feature.geometry.coordinates as LngLatBoundsLike))
  return bounds
}

export const getCityCenter = (cityName: string, reusableDropoffs: ReusableDropoffType[]) => {
  if (CITIES_CENTER[cityName.toLowerCase()]) {
    return CITIES_CENTER[cityName.toLowerCase()]
  }
  const features = reusableDropoffs.map(buildFeature)
  const bounds = featuresBounds(features)
  return bounds.getCenter()
}
