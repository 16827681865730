import React from 'react'

import Difference from './Difference'
import GreenMap from './GreenMap'
import './Hero.less'
import HeroForm from './HeroForm'
import HowItWorks from './HowItWorks'
import RestaurantLogos from './RestaurantLogos'

const Hero = () => {
  return (
    <div className="green-hero">
      <HeroForm />
      <RestaurantLogos />
      <HowItWorks />
      <GreenMap />
      <Difference />
    </div>
  )
}

export default Hero
